<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '80vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-list" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Documento Creado Bodega</strong>
        </h4>
      </div>
    </template>
    <Card class="w-full">
      <template #content>
        <div class="w-full text-xxs">
          <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
            <div class="grid grid-rows-4 gap-1 pt-3">
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Código Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.CardCode"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Nombre Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.CardName"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>N. Factura: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.NumAtCard"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>N. Pedidos: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.numero_pedidos"
                             disabled/>
                </div>
              </div>
            </div>
            <div class="grid grid-rows-4 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de Contabilización: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.TaxDate"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de documento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.DocDate"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de vencimiento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.DocDueDate"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Estado: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="dataRecepcionBodega.estadoNombre"
                             disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="mt-6">
          <div>
            <DataTable
              :value="dataRecepcionBodega.detalle"
              class="p-datatable-sm text-xxs"
              showGridlines
              dataKey="id"
              responsiveLayout="scroll"
              scrollHeight="500px"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column field="LineNum" header="Linea" headerStyle="justify-content: center;" style="min-width:1rem"
                      bodyStyle="text-align: center">
                <template #body="{data}">
                  <div>{{ data.LineNum }}</div>
                </template>
              </Column>
              <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;"
                      style="min-width:25rem" bodyStyle="text-align: center">
                <template #body="{data}">
                  <div class="grid grid-cols-1 w-full">
                    <div class="flex justify-between items-center my-1">
                      <div>
                        <strong>Código Mx: </strong>{{ data.ItemCode }}
                      </div>
                      <div class="flex gap-2">
                        <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                               class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                        <Badge v-if="data.Controlado" value="Controlado"
                               class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.Regulado" value="Regulado"
                               class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.temperatura > 0" value="Cadena Frio"
                               class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                      </div>
                    </div>
                    <div class="flex">
                      <strong>Nombre: </strong>{{ data.ItemName }}
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;" style="min-width:1rem"
                      bodyStyle="text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.Quantity) }}</div>
                </template>
              </Column>
              <Column field="lotes" header="Lotes" headerStyle="justify-content: center;" style="min-width:1rem"
                      bodyStyle="text-align: center">
                <template #body="{data}">
                  <div>
                    <Button type="button" class="p-button-xs" icon="pi pi-th-large" label="Lotes"
                            @click="toggle(data)"/>
                  </div>
                </template>
              </Column>
              <Column field="distribucion" header="Distribución" headerStyle="justify-content: center;"
                      style="min-width:1rem" bodyStyle="text-align: center"
                      v-if="dataRecepcionBodega.detalle.some((x) => x.tieneDistribucion)">
                <template #body="{data}">
                  <div>
                    <Button type="button" class="p-button-xs" icon="pi pi-th-large" label="Distribución"
                            @click="toggleDistri($event,data,1)" aria-haspopup="true" aria-controls="overlay_panel"/>
                    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                                  style="width: 400px" :breakpoints="{'960px': '75vw'}">
                      <DataTable
                        :value="info"
                        class="p-datatable-sm text-xs"
                        showGridlines
                        dataKey="id"
                        responsiveLayout="scroll"
                      >
                        <Column field="CodBodega" header="Almacen" headerStyle="justify-content: center;"
                                style="min-width:1rem" bodyStyle="text-align: center">
                          <template #body="{data}">
                            <p class="text-xs">{{ data.CodBodega }} - {{ data.NombreBodega }}</p>
                          </template>
                        </Column>
                        <Column field="cantidad" header="Cant. Distribución" headerStyle="justify-content: center;"
                                style="min-width:1rem" bodyStyle="text-align: center">
                          <template #body="{data}">
                            <p class="text-xs">{{ $h.formatNumber(data.cantidad) }}</p>
                          </template>
                        </Column>
                      </DataTable>
                    </OverlayPanel>
                  </div>
                </template>
              </Column>
              <Column field="WarehouseCode" header="Almacén" headerStyle="justify-content: center;"
                      style="min-width:15rem" bodyStyle="text-align: center">
                <template #body="{data}">
                  <div>{{ data.WarehouseCode }} - {{ data.WhsName }}</div>
                </template>
              </Column>
              <Column field="Ingreso" header="Pedido" headerStyle="justify-content: center;" style="min-width:1rem"
                      bodyStyle="text-align: center">
                <template #body="{data}">
                  <div>{{ data.BaseRef }}</div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
    <Card class="w-full mt-4">
      <template #content>
        <div class="w-full text-xxs grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Comentarios: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                v-model="dataRecepcionBodega.Comments"
                rows="2"
                cols="40"
              />
            </div>
            <div>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Sidebar
      v-model:visible="displaySideBar"
      position="bottom"
      :showCloseIcon="false"
      class="p-sidebar-sm"
      style="height: 15rem"
    >
      <div v-if="infoMedSeleccionado.ItemCode" class="flex justify-center text-base mb-2">
        <span>{{ infoMedSeleccionado.ItemCode }} - {{ infoMedSeleccionado.ItemName }}</span>
      </div>
      <div>
        <DataTable
          :value="info"
          class="p-datatable-sm text-xxs"
          showGridlines
          responsiveLayout="scroll"
          scrollHeight="500px"
          dataKey="id"
          rowGroupMode="rowspan"
          :groupRowsBy="info.some(a => a.novedades && a.novedades.length) ? ['DistNumber', 'CantidadLote', 'ExpDate','SL1Code'] : []"
        >
          <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="CantidadLote" header="Cant. Lote" headerStyle="justify-content: center;"
                  style="width:5rem" bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ $h.formatNumber(data.CantidadLote) }}</div>
            </template>
          </Column>
          <Column field="ExpDate" header="Fech. Vencimiento" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="SL1Code" header="Ubicación" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="nombreTipoNov" header="Tipo de novedad" headerStyle="justify-content: center;"
                  bdyStyle="text-align: center" style="min-width:5rem">
            <template #body="{data}">
              <div>{{ data.nombreTipoNov }}</div>
            </template>
          </Column>
          <Column field="nombreDetalleNov" header="Detalle de novedad" headerStyle="justify-content: center;"
                  bodyStyle="text-align: center" style="min-width:10rem">
            <template #body="{data}">
              <div v-if="data.codigoDetalleNov">{{ data.codigoDetalleNov }} - {{ data.nombreDetalleNov }}</div>
            </template>
          </Column>
          <Column field="observacion" header="Observación" headerStyle="justify-content: center;"
                  bodyStyle="text-align: center; word-break: break-all;" style="min-width:5rem"/>
          <Column field="" header="Acciones" headerStyle="justify-content: center;"
                  style="width:5rem" bodyStyle="text-align: center" v-if="info.some((a) => a.url)">
            <template #body="{data}">
              <Button v-tooltip.top="'Ver carta compromiso'" v-if="data.url" @click="viewCarta(data.id)"
                      class="p-button-text p-button-info p-0">
                <eyeIcon class="w-3/4"/>
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </Sidebar>
  </Dialog>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

export default {
  name: 'modalJsonBodega',
  components: {
    OverlayPanel: defineAsyncComponent(() =>
      import('primevue/overlaypanel')
    )
  },
  setup () {
    const displayModal = ref(false)
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const dataRecepcionBodega = ref({})
    const displaySideBar = ref(false)
    const infoMedSeleccionado = ref({})
    const op = ref()
    const info = ref([])
    const openModalBodega = (values) => {
      values.detalle.forEach((element) => {
        if (element.novedades) element.tieneNovedad = true
        if (element.distribucion) element.tieneDistribucion = true
      })
      dataRecepcionBodega.value = values
      displayModal.value = true
    }
    const viewCarta = (id) => {
      _RecepcionPedidosService.verPdf(id)
    }
    const toggleDistri = (event, data) => {
      info.value = data.distribucion
      op.value.toggle(event)
    }
    const toggle = (data) => {
      info.value = []
      infoMedSeleccionado.value = {}
      for (const lot of data.lotes) {
        if (lot.novedades && lot.novedades.length) {
          for (const nov of lot.novedades) {
            info.value.push({
              ...lot,
              ...nov
            })
          }
          continue
        }
        info.value.push({
          ...lot,
          novId: null,
          idNovedad: null,
          codigoDetalleNov: null,
          nombreTipoNov: null,
          nombreDetalleNov: null,
          observacion: null,
          SL1Code: null
        })
      }
      infoMedSeleccionado.value = {
        ItemCode: data.ItemCode,
        ItemName: data.ItemName
      }
      displaySideBar.value = true
    }
    return {
      openModalBodega,
      displayModal,
      dataRecepcionBodega,
      op,
      toggle,
      info,
      viewCarta,
      displaySideBar,
      infoMedSeleccionado,
      toggleDistri
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}
</style>
